
/* ========================================================================
 *
 * Custom site functions
 *
 * See also:
 * ../bower_components/fanglestrap
 *
 * ======================================================================== */

var overLayFadeInSpeed = 200;

var windowwidth = $(window).width();

function windowsizeinit() {
	windowwidth = $(window).width();
	if (windowwidth <= 1024) {
		var h1height = $('.post-header h1').height();
		$('.left-main.has-filter').css('padding-top',(h1height+10)+'px');
	} else {
		$('.left-main.has-filter').css('padding-top','13px');
	}
}

/* ========================================================================
 *  On document ready, do all the things
 * ======================================================================== */
$(document).ready(function() {
	$('.uwpqsf_class span').click(function(event){
		$(this).closest('.uwpqsf_class').toggleClass('active');
	});

	$('.menu-sidebar h2,.menu-sidebar h3').click(function(event){
		$(this).closest('.menu-sidebar').toggleClass('active');
	});

	$('.menu-sidebar .cmw-level-1 > a').click(function(event){
		if (windowwidth <= 1024) {
			var isActive = $(this).closest('.menu-sidebar').hasClass('active');

			if (!isActive) {
				event.preventDefault();
				$(this).closest('.menu-sidebar').toggleClass('active');
			}
		}
	});

	$('.anchor-down').click(function(event){

		event.preventDefault();

		var anchor = $(this).attr('href');

		$('html, body').animate({
		    	scrollTop: $(anchor).offset().top
		}, 2000);
	});

	windowsizeinit();
});

$( window ).resize(function() {
	windowsizeinit();
});


$(function() {
    jQuery(document).bind('gform_confirmation_loaded', function(e){

        jQuery('.blog-cta-original').show();
        jQuery('.blog-cta-placeholder').hide();

        var offsetTop = $(".gform_confirmation_message").offset().top;
        offsetTop = offsetTop - 100;

        jQuery('html,body').animate({
           scrollTop:  offsetTop
        });

    });

    jQuery(document).on( 'blog-ajax-loaded', function(){

        if (jQuery('.blog-cta-original').find('.gforms_confirmation_message').length) {

            return;

        }

        jQuery('.blog-cta-placeholder').each( function(){

            if (jQuery(this).hasClass('cta-loaded')) {
                return;
            }

            formObj = jQuery('.blog-cta-original').html();
            jQuery(this).html( formObj );
            jQuery('.blog-cta', this).show();

            jQuery(this).addClass('cta-loaded');
        });
    })

/* ========================================================================
 *  Init the slideshows
 *  http://kenwheeler.github.io/slick/
 * ======================================================================== */

    jQuery('.content-typography .gallery br').remove();

    jQuery('.content-typography .gallery').each(function() {
        $(this).slick({
            infinite: true,
            autoplay: false,
            speed: 300,
            slidesToShow: 1,
            dots: false
        });
    })

    jQuery('.slideshow-wrapper').each(function() {
        $(this).slick({
            infinite: true,
            autoplay: true,
            speed: 300,
            slidesToShow: 1,
            dots: true,
            appendDots:$(this).siblings('.dots-container')
        });
    })

	$('.slideshow-wrapper').on('setPosition', function(slick){
		var height = $(this).find('.slick-current img').height();
		$('.slick-prev,.slick-next').css('top',(height/2)+'px')
	});


    $('.slideshow-wrapper button').on('click', function(){
          jQuery('.slideshow-wrapper').slick('slickPause');
          jQuery('.slideshow-wrapper').slick('setOption', 'autoplay', false, false);
    });

    //NEW (MARCH 2022): RESULTS GRID IMAGE SLIDER BLOCK
    $('.r_img_slider').each(function(i) {
	    var t = $(this);

        /*t.on('init', function(event, slick) {
            var t_li = t.find('.slick-slide');
            rowMaxHeight(t_li);
        });*/

        /*$(window).resize(function() {

            setTimeout(function(){
                t.slick('resize');
                var t_li = t.find('.slick-slide');
                rowMaxHeight(t_li);
            },100);
        });*/

        var img_slider_config = {
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            //centerMode: true,
            variableWidth: false,
            adaptiveHeight: false
        }
        var ImgSlider = t.slick(img_slider_config);

    });

    //NEW (MARCH 2022): HEADER ALERT BAR CLOSE BUTTON
    // #TODO--------------------------------------------------------SET LOCALSTORAGE COOKIE TO REMEMBER CLOSED STATE ONCE CLICKED

    const isNoticeSeen = window.localStorage['noticeSeen'] === 'true';
    if ( isNoticeSeen ) {
      //$('#alertbar').css('display','none');
    }

    $('#alertbar .close').on('click', function(e) {
      e.preventDefault();
      $('#alertbar').addClass('kill');
      //window.localStorage['noticeSeen'] = true;
    });

    //NEW JAN 2023: LEFT NAV TOGGLE
    $('.left-sidebar .sidebar-inner .sb-toggle').on('click', function(e) {
      e.preventDefault();
      var t = $(this),
          m = t.parent().find('.menu');
      m.find('.sub-menu').slideToggle();
    });

/* ========================================================================
 *  Mobile navigation
 * ======================================================================== */

    jQuery('.navbar-toggle').on( 'click', toggleMobileNav );

    function toggleMobileNav() {

        jQuery('.navbar-collapse').toggleClass('navbar-collapse-open');
        jQuery('#nav-toggle').toggleClass('active');
        jQuery('body').toggleClass('nav-active');
    }


/* ========================================================================
 *  Overlays
 * ======================================================================== */

    jQuery( '.share-email-trigger').on('click', function(){
        jQuery('#share-overlay').fadeIn( overLayFadeInSpeed );
        jQuery('body').addClass('overlay-open');
    })


    jQuery( '.email-trigger').on('click', function(event){
		event.preventDefault();
        jQuery('#email-overlay').fadeIn( overLayFadeInSpeed );
        jQuery('body').addClass('overlay-open');
    })


    jQuery( '.nav-search').on('click', function(){
        jQuery('#search-overlay').fadeIn( overLayFadeInSpeed );
        jQuery('body').addClass('overlay-open');
        jQuery('#seach-input').focus();
    })

    jQuery('.overlay-close').on( 'click', function(event){
        jQuery('.overlay').fadeOut( overLayFadeInSpeed );
        jQuery('body').removeClass('overlay-open');
    } );

    $(document).keyup(function(e) {

        if (e.keyCode === 27) {
            jQuery('.overlay').fadeOut( overLayFadeInSpeed );
            jQuery('body').removeClass('overlay-open');
        }
    });

/* ========================================================================
 *  Search
 * ======================================================================== */

    $("#seach-input").on('keyup', prepareSearchContainer);
    $("#seach-input").on('keyup', $.debounce( 50, doSearch ));

    function prepareSearchContainer() {
    //    $('#search-results').empty();
        $('#search-results').addClass('loading')
    }

    function doSearch() {
        var search_val=$("#seach-input").val();
        $.ajax({
            type:"POST",
            url: "/wp-admin/admin-ajax.php",
            data: {
                action:'ajax_search',
                search_string:search_val
            },
            success:function(data){
                $('#search-results').removeClass( 'loading' );
                $('#search-results').show();
                $('#search-results').html(data);
            }
        });
    }

/* ========================================================================
 *  Hover images
 * ======================================================================== */

    jQuery('[data-hoverimg]').hover( function(){

        if ($(this).attr('data-hover-target')) {
            dataTarget = $(this).attr('data-hover-target');
            targetEl = $(dataTarget, $(this));
        } else {
            targetEl = $(this);
        }

        var hoverImageOn = $(this).attr( 'data-hoverimg' );
        var currentImage = targetEl.attr( 'src' );

        if ( hoverImageOn ) {
            targetEl.attr( 'src', hoverImageOn )
            targetEl.attr( 'data-previmg', currentImage )
        }

    }, function(){

        var hoverImageOff = targetEl.attr( 'data-previmg' );

        if ( hoverImageOff ) {
            targetEl.attr( 'src', hoverImageOff )
            targetEl.attr( 'data-previmg', '' )
        }
    })

/* ========================================================================
 *  Click-to-play videos
 * ======================================================================== */
    jQuery('.video-wrapper .play').on( 'click', function(){
		$(this).closest('.video-wrapper').find('img[data-video]').click();
    });

    jQuery('img[data-video]').on( 'click', function(){
        var containerHeight = jQuery(this).height();

        jQuery(this).parent().css('height', containerHeight );

        var video = JSON.parse( jQuery(this).attr('data-video') );
        $(this).replaceWith(video);

        jQuery('.video-wrapper').fitVids();
    });

    jQuery('.video-wrapper').fitVids();
    jQuery('.banner-video').fitVids();


/* ========================================================================
 *  Open external links in new window
 * ======================================================================== */

    jQuery('a').each(function() {

        var a = new RegExp('/' + window.location.host + '/');

        if(!a.test(this.href)) {
            jQuery(this).click(function(event) {

                if (jQuery(this).attr('id') === 'consoleWorkspace-close') {
                    return;
                }

                event.preventDefault();
                event.stopPropagation();
                window.open(this.href, '_blank');
            });
        }
    });

/* ========================================================================
 *  share-link-trigger
 * ======================================================================== */

    jQuery('.share-link-trigger').on('click', function(event){

        if (jQuery(this).attr('data-share')) {

            event.stopPropagation();
            event.preventDefault();

            var left  = ($(window).width()/2)-(900/2),
                top   = ($(window).height()/2)-(600/2),
                popup = window.open (jQuery(this).attr('data-share'), "popup", "width=900, height=600, top="+top+", left="+left);
        }
    });

/* ========================================================================
 *  Share animations
 * ======================================================================== */

    $('.share-link-trigger, .share-email-trigger').hover( function(){

        $(this).animate({
            'margin-left': '0px'
        }, 200 );

    }, function(){

        if ($(window).width() > 769) {
            $(this).animate({
                'margin-left': '-247px'
            }, 200 );
        } else {
            $(this).animate({
                'margin-left': '-262px'
            }, 200 );
        }
    })

    function fixShareMargins() {

        $('.share-link-trigger, .share-email-trigger').each( function(){

            if ($(window).width() > 769) {
                console.log( 1 );
                $(this).css('margin-left', '-247px');
            } else {
                $(this).css('margin-left', '-262px');
            }
        });
    }

    $(window).on( 'resize', $.debounce( 100, fixShareMargins ));

    /* ========================================================================
     *  Make the nav dropdown items clickable
     * ======================================================================== */
    (function ($, window, delay) {


        // http://jsfiddle.net/AndreasPizsa/NzvKC/
        var theTimer = 0;
        var theElement = null;
        var theLastPosition = {x:0,y:0};
        var mobileMenuBreakpoint = 768;

        $('[data-toggle]')
            .on('click', function (inEvent) {

                var width = $(window).width();

                theElement = $(this);

                if (width > mobileMenuBreakpoint || theElement.closest('li').hasClass('open')) {
                    //theElement = $(this);

                    if (theElement.attr('href')) {
                        inEvent.stopPropagation();
                        window.location = theElement.attr('href');
                    }
                }
            })
            .closest('li')
            .on('mouseenter', function (inEvent) {


                var width = $(window).width();

                if (width > mobileMenuBreakpoint)
                {
                    if (theElement) {
                        theElement.removeClass('open');
                    }
                    window.clearTimeout(theTimer);
                    theElement = $(this);

                    theTimer = window.setTimeout(function () {
                        theElement.addClass('open');
                    }, delay);
                }
            })
            .on('mousemove', function (inEvent) {

                var width = $(window).width();

                if (width > mobileMenuBreakpoint)
                {
                    if( Math.abs(theLastPosition.x - inEvent.ScreenX) > 4 ||
                        Math.abs(theLastPosition.y - inEvent.ScreenY) > 4) {
                            theLastPosition.x = inEvent.ScreenX;
                            theLastPosition.y = inEvent.ScreenY;
                            return;
                    }

                    if (theElement.hasClass('open')){
                        return;
                    }

                    window.clearTimeout(theTimer);

                    theTimer = window.setTimeout(function () {
                        theElement.addClass('open');
                    }, delay);
                }
            })
            .on('mouseleave', function (inEvent) {

                var width = $(window).width();

                if (width > mobileMenuBreakpoint)
                {
                    window.clearTimeout(theTimer);
                    theElement = $(this);

                    theTimer = window.setTimeout(function () {
                        theElement.removeClass('open');
                    }, delay);
                }
            });
    })(jQuery, window, 0); // 200 is the delay in milliseconds

});


