
/* ========================================================================
 *
 *  Insights AJAX filter functionality
 *
 * ======================================================================== */

$(function() {

    //-------------------------------------------------------------------------------------------------------
    //  Insights infinate scroll functionality
    //  When we get to the bottom of the page, trigger the 'next' button
    //-------------------------------------------------------------------------------------------------------
        jQuery(window).scroll(function () {

            if(jQuery(window).scrollTop() + jQuery(window).height() > jQuery(document).height() - 200) {
                jQuery( ".blog-more" ).trigger( "click" );
            }
        });
    //-------------------------------------------------------------------------------------------------------
    //  Ajax function for loading of the Insights list
    //-------------------------------------------------------------------------------------------------------
        var isMoreLoading = false;

        window.upagi_ajax2 = function (pagenum, formid) {

            var ajxdiv = jQuery(''+formid+'').find("#uajaxdiv").val();
            var res = {loader:jQuery('<div />',{'class':'umloading'}),container : jQuery(''+ajxdiv+'')};
            var getdata = jQuery(''+formid+'').serialize();

            jQuery.ajax({
                type: 'POST',
                url: ajax.url,
                data: ({action : 'uwpqsf_ajax',getdata:getdata, pagenum:pagenum }),
                    beforeSend:function() {res.container.append(res.loader);},
                    error: function(){
                },
                success: function(html) {
                    res.container.find(res.loader).remove();
                    jQuery('#blog-list').append(html);
                    isMoreLoading = false;

                    $(document).trigger('blog-ajax-loaded');
                    $(window).resize();

                }
            });
        }

    //-------------------------------------------------------------------------------------------------------
    //  When an Insights filter is clicked, trigger the 'search' button
    //      Unless we're on a subpage, in which case send the user back to
    //      the list page
    //-------------------------------------------------------------------------------------------------------
        jQuery('#uwpqsf_id input').on('change', function(){
            if (jQuery(this).is(':checked'))
            {
                jQuery(this).parent().addClass( 'selected' );
            }
            else
            {
                jQuery(this).parent().removeClass( 'selected' );
            }
            if (jQuery('body').hasClass('single')) {
                if (jQuery(this).hasClass('tchkb-0')){
                    var urlParm = jQuery(this).attr('value');
                    window.location = '/blog/?category='+urlParm;
                } if (jQuery(this).hasClass('tchkb-1')){
                    var urlParm = jQuery(this).attr('value');
                    window.location = '/blog/?author='+urlParm;
                } else {
                    var urlParm = jQuery(this).attr('value');
                    window.location = '/blog/?category='+urlParm;
                }
            }
            if (jQuery(this).closest("form"))
            {
                $('html,body').animate({
                    scrollTop: $('#blog-list').offset().top - 100
                }, 200, function(){
                    $(window).resize();
                });


                jQuery('#uwpqsf_id_btn', jQuery(this).closest("form")).trigger( 'click' );
            }
        })
    //-------------------------------------------------------------------------------------------------------
    //  When the Insights more button is clicked (by scrolling the page, or by a bot without js),
    //      trigger the next search page load
    //-------------------------------------------------------------------------------------------------------
        jQuery('body').on('click','.blog-more', function(e) {

            e.preventDefault();
            e.stopPropagation();

            if (isMoreLoading)
            {
                return;
            }

            isMoreLoading = true;

            var _this = this;

            var pagenumber =  jQuery(this).attr('data-id');
            var formid = jQuery(this).attr('data-formid');

            upagi_ajax2(pagenumber, formid);

            jQuery(this).animate({ 'opacity': 0}, 100, function(){
                jQuery(_this).remove();
            });


            return false;
        });
});